<template>
    <img alt="exceljutaimages" :src="getImgUrl()">
</template>

<script>
export default {
  props: {
    fpath: String
  },
  methods: {
    getImgUrl()
    {
        return require(`../assets/images/${this.fpath}`)
    }
  },
}
</script>


